<div class="date-selector-container">
    <p-calendar
        [(ngModel)]="startDate"
        [placeholder]="startDatePlaceholder"
        [firstDayOfWeek]="1"
        [dateFormat]="dateFormat"
        [minDate]="startMinDate"
        [maxDate]="startMaxDate ? startMaxDate : endDate ? endDate : null"
        [pTooltip]="startDateTooltip"
        [monthNavigator]="monthSelector"
        [disabled]="startDateDisabled"
        [disabledDates]="startDisabledDates"
        (onSelect)="startDateSelect($event)"
        (onClose)="startDateClose()"
        (onMonthChange)="monthChanged($event)"
        (onShow)="show()"
        [showButtonBar]="showTodayButton"
        clearButtonStyleClass="hide"
        appendTo="body"
        [styleClass]="styleClass"
    >
    </p-calendar>
    <p-calendar
        *ngIf="useEndDate"
        [(ngModel)]="endDate"
        [placeholder]="endDatePlaceholder"
        [firstDayOfWeek]="1"
        [dateFormat]="dateFormat"
        [minDate]="endMinDate ? endMinDate : startDate ? startDate : null"
        [maxDate]="endMaxDate"
        [pTooltip]="endDateTooltip"
        [disabled]="endDateDisabled"
        (onSelect)="endDateSelect($event)"
        (onClose)="endDateClose()"
        appendTo="body"
        [styleClass]="styleClass"
    ></p-calendar>
</div>
