import { Agent } from 'src/app/models/agent';
import { ProviderContact } from './provider-contact';

export class User {
    id: number;
    username?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    displayName?: string;
    token: string;
    refreshToken: string;
    is_superuser: boolean = false;
    permissions?: string[];
    groups?: string[];
    agent?: Agent;
    contact?: ProviderContact;
    is_active?: boolean;

    constructor(params: any) {
        Object.assign(this, params);
    }

    public is?(groupName: string): boolean {
        return this.groups ? this.groups.includes(groupName) : false;
    }

    can?(permission: string): boolean {
        return (
            this.is_superuser || this.permissions.some((p) => p == permission)
        );
    }
}
