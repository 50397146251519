<div style="width: 120px">
    <div class="p-float-label">
        <p-dropdown [disabled]="disabled" [options]="countries" [(ngModel)]="selectedCountry"
            optionLabel="translations.fra.common" [filter]="true" filterBy="translations.fra.common"
            (onChange)="change($event)" placeholder="Sélectionner un pays" appendTo="body">
            <ng-template pTemplate="selectedItem">
                <div class="p-d-flex p-ai-center" *ngIf="selectedCountry">
                    <img src="{{ selectedCountry.flags.svg }}" style="height: 20px; width: 30px" class="p-mr-2" />
                    <div>
                        {{
                        selectedCountry.idd.root +
                        selectedCountry.idd.suffixes[0]
                        }}
                    </div>
                </div>
            </ng-template>
            <ng-template let-country pTemplate="item">
                <div class="p-d-flex p-ai-center">
                    <img src="{{ country.flags.svg }}" style="height: 15px; width: 25px" class="p-mr-2" />
                    <div>{{ country.translations.fra.common }}</div>
                </div>
            </ng-template>
        </p-dropdown>
        <!-- <label for="countryList">Pays</label> -->
    </div>
</div>