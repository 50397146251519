import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MaintenanceRequest } from '../models/maintenance-request';
import { PaginatedMaintenanceRequest } from '../models/paginated-maintenance-request';
import { PaginatedParams } from '../models/paginated-params';
import { QueryParams } from '../models/query-params';
import { ApiService } from './api.service';
import { IdAccountService } from './id-account.service';
import { HistoryEntry } from '../models/history-entry';
import { AccountProvider } from '../models/account-provider';
import { AddComment } from './add-comment';
import { Comment } from '../models/comment';

@Injectable({
    providedIn: 'root',
})
export class MaintenanceRequestService
    extends ApiService
    implements AddComment
{
    baseUrl: string;
    accountId: number;

    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        private IdAccountService: IdAccountService
    ) {
        super(http, dialogService);
        this.accountId = this.IdAccountService.getIdAccountService();
        this.baseUrl = `api/thot/accounts/${this.accountId}/poseidon/maintenanceRequests`;
    }

    public getMaintenanceRequests(
        queryParams: QueryParams
    ): Promise<MaintenanceRequest[]> {
        return this.getRequest(this.baseUrl, queryParams);
    }

    public getMaintenanceRequest(
        id: number,
        queryParams: QueryParams
    ): Promise<MaintenanceRequest> {
        return (
            this.getDetailRequest(
                `${this.baseUrl}/${id}`,
                queryParams
            ) as Promise<MaintenanceRequest>
        ).then((maintenanceRequest) => {
            if (maintenanceRequest.documents) {
                const documents = [];
                maintenanceRequest.documents.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    documents.push(document);
                });
                maintenanceRequest.documents = documents;
            }
            return maintenanceRequest;
        });
    }

    public getPaginatedMaintenanceRequests(
        queryParams: QueryParams,
        paginatedParams: PaginatedParams
    ): Promise<PaginatedMaintenanceRequest> {
        return (
            this.paginatedRequest(
                `${this.baseUrl}`,
                queryParams,
                paginatedParams
            ) as Promise<PaginatedMaintenanceRequest>
        ).then((requests) => {
            requests.results = requests.results?.map((result) => {
                let document_urls = [];
                result.documents?.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    document_urls.push(document);
                });
                result.documents = document_urls;
                return result;
            });
            return requests;
        });
    }

    public getPaginatedMaintenanceRequest(
        queryParams: QueryParams,
        paginatedParams: PaginatedParams
    ): Promise<PaginatedMaintenanceRequest> {
        return this.paginatedRequest(
            `${this.baseUrl}`,
            queryParams,
            paginatedParams
        );
    }

    public createMaintenanceRequest(data: {
        [key: string]: any;
    }): Promise<MaintenanceRequest> {
        const queryParams: QueryParams = {
            fields: 'id',
        };
        return (
            this.postRequest(
                `${this.baseUrl}`,
                data,
                queryParams
            ) as Promise<MaintenanceRequest>
        ).then((maintenanceRequest) => {
            if (maintenanceRequest?.documents) {
                const documents = [];
                maintenanceRequest.documents.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    documents.push(document);
                });
                maintenanceRequest.documents = documents;
            }
            return maintenanceRequest;
        });
    }

    public updateMaintenanceRequest(
        id: number,
        data: { [key: string]: any },
        queryParams?: QueryParams
    ): Promise<MaintenanceRequest> {
        return (
            this.patchRequest(
                `${this.baseUrl}/${id}`,
                data,
                queryParams
            ) as Promise<MaintenanceRequest>
        ).then((maintenanceRequest) => {
            if (maintenanceRequest?.documents) {
                const documents = [];
                maintenanceRequest.documents.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    documents.push(document);
                });
                maintenanceRequest.documents = documents;
            }

            return maintenanceRequest;
        });
    }

    public updateMaintenanceRequests(
        ids: number[],
        patchData: { [key: string]: any },
        queryParams?: QueryParams
    ): Promise<MaintenanceRequest[]> {
        const data = {
            ids: ids,
            data: patchData,
        };
        return this.patchRequest(
            `${this.baseUrl}/multiple_update`,
            data,
            queryParams
        );
    }

    public destroyMaintenanceRequestImage(
        idMaintenanceRequest: number,
        idImage: number
    ): Observable<any[]> {
        const data = {
            id: idImage,
        };
        return this.http
            .post<any[]>(
                `${environment.apiHost}/${this.baseUrl}/${idMaintenanceRequest}/destroyImage/`,
                data
            )
            .pipe(
                map((files) => {
                    const images = [];
                    files.forEach((image) => {
                        image.file = `${environment.apiHost}/media/filer/${image.file}`;
                        images.push(image);
                    });
                    return images;
                })
            );
    }

    // response blob
    public downloadMaintenanceRequestsPDF(
        date: string,
        provider: AccountProvider
    ): Observable<Blob> {
        let dateParam = `?date=${date}&providerId=${provider.id}`;
        return this.http.get(
            `${environment.apiHost}/${this.baseUrl}/pdf/${dateParam}`,
            {
                responseType: 'blob',
            }
        );
    }

    // response blob
    public downloadMaintenanceRequestPDF(
        maintenanceRequestId: number,
        documentType: string
    ): Observable<Blob> {
        const data = {
            documentType: documentType,
        };
        return this.http.post(
            `${environment.apiHost}/${this.baseUrl}/${maintenanceRequestId}/pdf_maintenance_request/`,
            data,
            {
                responseType: 'blob',
            }
        );
    }

    public getMaintenanceRequestBoard(filters): Promise<MaintenanceRequest[]> {
        let queryParams: QueryParams = {
            fields: [
                'id',
                'created',
                'content',
                'vehicle.id',
                'vehicle.registration',
                'vehicle.park_code',
                'provider.id',
                'provider.designation',
                'title',
                'status',
                'repair_date',
                'repair_time',
                'end_repair_date',
                'end_repair_time',
                'documents',
                'maintenance_type.label',
                'maintenance_subtype.label',
                'limit_date',
                'priority',
                'status_changed',
                'done_date',
                'maintenance_plan_element.id',
                'maintenance_plan_element.designation',
                'priority_item_movements_status',
            ],
            expand: [
                'vehicle',
                'provider',
                'maintenance_type',
                'maintenance_subtype',
                'maintenance_plan_element',
            ],
            filters: filters,
        };
        return (
            this.getRequest(`${this.baseUrl}/board`, queryParams) as Promise<
                MaintenanceRequest[]
            >
        ).then((requests) => {
            requests.forEach((request) => {
                let document_urls = [];
                request.documents.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    document_urls.push(document);
                });
                request.documents = document_urls;
            });
            return requests;
        });
    }

    public getMaintenanceRequestCalendar(
        queryParams: QueryParams
    ): Promise<MaintenanceRequest[]> {
        return this.getRequest(`${this.baseUrl}/calendar`, queryParams);
    }

    // TODO : change view in back
    public getAvailableProviders(
        queryParams?: QueryParams
    ): Promise<AccountProvider[]> {
        return this.getRequest(
            `${this.baseUrl}/availableProviders`,
            queryParams
        );
    }

    public addComment(
        id: number,
        data: { [key: string]: any }
    ): Promise<Comment> {
        return this.postRequest(`${this.baseUrl}/${id}/addComment`, data);
    }

    public getMaintenanceRequestsExport(queryParams: QueryParams) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let httpParams = this.setHttpParams(queryParams);
        return this.http.get<any>(
            `${environment.apiHost}/${this.baseUrl}/export/`,
            {
                headers: httpHeaders,
                params: httpParams,
                responseType: 'blob' as 'json',
            }
        );
    }

    public sendMaintenanceRequestEmail(
        maintenanceRequestId: number,
        data: { [key: string]: any }
    ): Promise<any> {
        return this.postRequest(
            `${this.baseUrl}/${maintenanceRequestId}/send_email`,
            data
        );
    }

    public transfertProvider(maintenanceRequestId: number, data): Promise<any> {
        return this.postRequest(
            `${this.baseUrl}/${maintenanceRequestId}/transfert_provider`,
            data
        );
    }

    public getMaintenanceRequestHistory(
        maintenanceRequestId: number
    ): Promise<HistoryEntry[]> {
        return this.getRequest(
            `${this.baseUrl}/${maintenanceRequestId}/history`
        );
    }
}
