import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { ApolloButtonComponent } from '../apollo-button/apollo-button.component';
import { ButtonTypeEnum } from 'src/app/enums/button-type';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    standalone: true,
    selector: 'app-filter-buttons',
    templateUrl: './filter-buttons.component.html',
    styleUrls: ['./filter-buttons.component.scss'],
    imports: [
        ApolloButtonComponent,
        ReactiveFormsModule,
        CommonModule,
        OverlayPanelModule,
        CommonModule,
        TooltipModule,
    ],
})
export class FilterButtonsComponent {
    @Input() useFilter: boolean = true;
    @Input() disableFilter: boolean = false;
    @Input() filterTooltip: any;
    @Input() filterTooltipPosition: any;
    @Output() filterButtonClick: EventEmitter<void | any> = new EventEmitter();

    @Input() useClear: boolean = false;
    @Input() disableClear: boolean = false;
    @Input() clearTooltip: any;
    @Input() clearTooltipPosition: any;
    @Output() clearButtonClick: EventEmitter<void> = new EventEmitter();

    public buttonEnum = ButtonTypeEnum;

    constructor() {}

    public onfilterButtonClick() {
        this.filterButtonClick.emit();
    }

    public onClearButtonClick() {
        this.clearButtonClick.emit();
    }
}
