import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';

@Injectable({ providedIn: 'root' })
export class UserIssuesService extends ApiService {
    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService
    ) {
        super(http, dialogService);
    }

    public createBugReport(
        data: { [key: string]: any },
        file: any
    ): Observable<string> {
        const formData: any = new FormData();
        formData.append('title', data.title);
        formData.append('content', data.content);
        formData.append('type', data.type);
        formData.append('choice', data.choice);
        if (file) {
            const blob = new Blob([file], { type: file.type });
            if (file.type.startsWith('image/')) {
                formData.append('file', blob);
                formData.append('fileName', file.name);
            }
        }
        // TODO: To investigate. Error with Promise
        // * this Promise return a Json parse error maybe caused by postRequest Header
        // return this.postRequest(`api/thot/user_issues`, formData);
        return this.http.post<string>(
            `${environment.apiHost}/api/thot/user_issues/`,
            formData
        );
    }
}
