import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AgentAccount } from '../models/agent-account';
import { PaginatedAgentAccounts } from '../models/paginated-agent-accounts';
import { PaginatedParams } from '../models/paginated-params';
import { QueryParams } from '../models/query-params';
import { ApiService } from './api.service';
import { IdAccountService } from './id-account.service';

@Injectable({
    providedIn: 'root',
})
export class AgentAccountService extends ApiService {
    private id: number;
    private baseUrl: string;

    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        private IdAccountService: IdAccountService
    ) {
        super(http, dialogService);
        this.id = this.IdAccountService.getIdAccountService();
        this.baseUrl = 'api/thot/accounts/' + this.id + '/chronos';
    }

    public getPaginatedAgentAccounts(
        queryParams: QueryParams,
        paginatedParams: PaginatedParams
    ): Promise<PaginatedAgentAccounts> {
        return this.paginatedRequest(
            `${this.baseUrl}/agentAccount`,
            queryParams,
            paginatedParams
        );
    }

    public getAgentAccounts(queryParams: QueryParams): Promise<AgentAccount[]> {
        return this.getRequest(`${this.baseUrl}/agentAccount`, queryParams);
    }

    public createAgentAccount(data: { [key: string]: any }): Promise<string> {
        return this.postRequest(`${this.baseUrl}/agentAccount`, data);
    }

    public partialUpdateAgentAccount(data: {
        [key: string]: any;
    }): Promise<AgentAccount> {
        return this.patchRequest(
            `${this.baseUrl}/agentAccount/${data.id}`,
            data
        );
    }
}
