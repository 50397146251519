import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Permission } from '../models/permission';
import { PermissionGroup } from '../models/permission_group';
import { QueryParams } from '../models/query-params';
import { ApiService } from './api.service';
import { IdAccountService } from './id-account.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionService extends ApiService {
    private baseUrl: string;
    // private permissions: Promise<PermissionGroup[]>;

    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        // private authenticationService: AuthenticationService,
        private IdAccountService: IdAccountService
    ) {
        super(http, dialogService);
        this.baseUrl = 'api/thot';
    }

    public getUserPermissions(id: number): Promise<string[]> {
        return this.getRequest(`${this.baseUrl}/accounts/${id}/permissions`);
    }

    public getAccountPermissionGroups(
        id: number,
        queryParams: QueryParams
    ): Promise<PermissionGroup[]> {
        return this.getRequest(
            `${this.baseUrl}/accounts/${id}/permission_groups`,
            queryParams
        );
    }

    public getAllPermissions(queryParams: QueryParams): Promise<Permission[]> {
        return this.getRequest(`${this.baseUrl}/permissions`, queryParams);
    }

    public async hasPermission(component: any): Promise<boolean> {
        const PermissionUser = await this.getUserPermissions(
            this.IdAccountService.currentAccount.id
        );
        return PermissionUser.includes(component.routeConfig.data.permissions);
    }
}
