import { Table } from 'primeng/table';
import Utils from './utils';
import { LabelledEntityWithCode } from 'src/app/models/labelled-entity-with-code';
import * as moment from 'moment';
import { FilterMetadata } from 'primeng/api';
import { PaginatedParams } from 'src/app/models/paginated-params';

export default class UtilsFilter {
    // TextColumnFilter componant, add rules to table filters
    public static async onFilter(event: any, table: Table) {
        let field = event.field;
        let values = {};
        values[field] = event[field];
        if (Utils.isNotNil(values[field])) {
            table.filters = {
                ...table.filters,
                ...values,
            };
        } else {
            delete table.filters[field];
        }
        table._filter();
    }

    // clear all columns filters in table
    // return true if there was values to know if we have to refresh listing
    public static clearTableFilterValues(table: Table) {
        for (const [, filterMetadata] of Object.entries(table.filters)) {
            if (Array.isArray(filterMetadata)) {
                for (let filter of filterMetadata) {
                    if (filter.value) {
                    }
                    filter.value = null;
                }
            } else if (filterMetadata) {
                if (filterMetadata.value) {
                }
                filterMetadata.value = null;
            }
        }
    }

    // for a multiselect
    // from a list of selected LabelledEntityWithCode and source list init filter for API by field
    // if not optionnalField and no selectedValues, send "empty"
    public static multiselectFilter(
        selectedValues: LabelledEntityWithCode[],
        values: any[],
        filters: any,
        field: string
    ) {
        if (selectedValues?.length > 0) {
            if (selectedValues?.length < values?.length) {
                filters[field] = selectedValues.map((e) => e.code).join(',');
            } else {
                delete filters[field];
            }
        } else {
            filters[field] = 'empty';
        }
        return filters;
    }

    public static dateFilter(
        date: Date | string,
        fieldName: string,
        filters: any
    ): any {
        if (date) {
            const formatedDate = moment(date).format('YYYY-MM-DD');
            filters[fieldName] = formatedDate;
        } else {
            delete filters[fieldName];
        }
        return filters;
    }

    public static setMultiSelect(
        event: any,
        valuesToSet: any,
        fieldname: string,
        table?: Table
    ) {
        valuesToSet[fieldname] = event;
        if (table) {
            table.reset();
        }
    }

    public static setCheckbox(
        event: any,
        valuesToSet: any,
        fieldName: string,
        table?: Table
    ) {
        valuesToSet[fieldName] = event?.checked;
        if (table) {
            table.reset();
        }
    }

    // manage period dates filters
    public static datesFilter(
        startDate: Date,
        endDate: Date,
        filters: any,
        field: string = 'date'
    ) {
        if (startDate && endDate) {
            filters[field] = `${moment(startDate).format(
                'YYYY-MM-DD'
            )}:${moment(endDate).format('YYYY-MM-DD')}`;
        } else {
            if (startDate && !endDate) {
                filters[field] = `${moment(startDate).format('YYYY-MM-DD')}:`;
            } else {
                if (!startDate && endDate) {
                    filters[field] = `:${moment(endDate).format('YYYY-MM-DD')}`;
                } else {
                    delete filters[field];
                }
            }
        }
        return filters;
    }

    // from table, generate filter object from table column filters
    public static setColumnFilters(filter: FilterMetadata[]) {
        const tempFilter = {};
        Object.keys(filter).forEach((colFilter) => {
            if (colFilter !== 'global') {
                if (filter[colFilter].length > 1) {
                    let filterValue = '';
                    filter[colFilter].forEach((subFilter) => {
                        if (
                            subFilter.value !== null &&
                            subFilter?.value?.toString().trim() !== ''
                        ) {
                            filterValue = filterValue + ',' + subFilter.value;
                        }
                    });
                    filterValue = filterValue.substring(1);
                    if (filterValue) {
                        tempFilter[colFilter] = filterValue;
                    } else {
                        delete tempFilter[colFilter];
                    }
                } else {
                    if (filter[colFilter][0]?.value !== null) {
                        tempFilter[colFilter] = filter[colFilter][0]?.value;
                    } else {
                        delete tempFilter[colFilter];
                    }
                }
            }
        });
        return tempFilter;
    }

    // set filters from existing filters and event
    public static setFilters(filters: any, event: any) {
        if (event) {
            filters = event;
            if (event.filters) {
                filters = UtilsFilter.setColumnFilters(event.filters);
            }
        }
        return filters;
    }

    // set PaginatedParams from globalSearch and event
    public static setPaginatedParams(
        globalSearch: string,
        event: any,
        ordering?: string
    ): any {
        let paginatedParams: PaginatedParams = {
            offset: 0,
            limit: 25,
            search: globalSearch ? globalSearch : '',
        };
        if (event) {
            if (event.filters) {
                paginatedParams.offset = event.first;
                paginatedParams.limit = event.rows;
            }
            if (event.rows) {
                paginatedParams.limit = event.rows;
            }
        }
        if (Utils.isNotNil(ordering)) {
            paginatedParams['ordering'] = ordering;
        }
        return paginatedParams;
    }

    private static isActiveTableColumns(table: Table): boolean {
        let filter: FilterMetadata | FilterMetadata[] = table.filters;
        let isActive = false;
        Object.keys(filter).forEach((colFilter) => {
            if (colFilter !== 'global') {
                if (filter[colFilter].length > 1) {
                    let filterValue = '';
                    filter[colFilter].forEach((subFilter) => {
                        if (
                            subFilter.value !== null &&
                            subFilter?.value?.toString().trim() !== ''
                        ) {
                            isActive = true;
                        }
                    });
                    filterValue = filterValue.substring(1);
                    if (filterValue) {
                        isActive = true;
                    }
                } else {
                    if (Utils.isNotNil(filter[colFilter][0]?.value)) {
                        isActive = true;
                    }
                }
            }
        });
        return isActive;
    }

    private static isActiveDates(
        startDate?: Date,
        endDate?: Date,
        initialStart?: Date,
        initialEnd?: Date
    ): boolean {
        if (startDate) {
            if (initialStart) {
                if (startDate.toDateString() !== initialStart.toDateString())
                    return true;
            } else {
                return true;
            }
        }
        if (endDate) {
            if (initialEnd) {
                if (endDate.toDateString() !== initialEnd.toDateString())
                    return true;
            } else {
                return true;
            }
        }
        if (initialStart && !startDate) {
            return true;
        }
        if (initialEnd && !endDate) {
            return true;
        }
        return false;
    }

    // if globalSearch or dates or active column filters return true
    public static isActiveListHeader(
        table: Table,
        globalSearch?: string,
        startDate?: Date,
        endDate?: Date,
        initialStart?: Date,
        initialEnd?: Date
    ): boolean {
        const isActiveTableColumns = table
            ? UtilsFilter.isActiveTableColumns(table)
            : false;
        const isActiveDates = UtilsFilter.isActiveDates(
            startDate,
            endDate,
            initialStart,
            initialEnd
        );
        const isActiveGlobalSearch = globalSearch ? true : false;
        return isActiveTableColumns || isActiveDates || isActiveGlobalSearch;
    }
}
