export enum ButtonTypeEnum {
    primary = 'primary',
    outlined = 'outlined',
    secondary = 'secondary',
    danger = 'danger',
    text = 'text',
    textDanger = 'danger-text',
    submit = 'submit',
    submitPrimary = 'submit-primary',
    warning = 'warning',
}
