import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Site } from '../models/site';
import { IdAccountService } from './id-account.service';
import { ApiService } from './api.service';
import { DialogService } from 'primeng/dynamicdialog';
import { QueryParams } from '../models/query-params';
import { DeliverySite } from '../models/delivery-site';

@Injectable({
    providedIn: 'root',
})
export class DeliverySiteService extends ApiService {
    private id: number;
    private baseUrl: string;
    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        private IdAccountService: IdAccountService
    ) {
        super(http, dialogService);
        this.baseUrl = `api/thot/accounts/${this.IdAccountService.getIdAccountService()}/hestia`;
    }

    // CUSTOMERS DELIVERY SITES
    public getCustomerSites(
        id: number,
        queryParams: QueryParams
    ): Promise<DeliverySite[]> {
        return this.getRequest(
            `${this.baseUrl}/customers/${id}/deliverySites`,
            queryParams
        );
    }

    public getDeliverySite(
        customerId: number,
        id: number,
        queryParams: QueryParams
    ): Promise<DeliverySite> {
        return this.getDetailRequest(
            `${this.baseUrl}/customers/${customerId}/deliverySites/${id}`,
            queryParams
        );
    }

    // for one customer, get all delivery sites and owner it-self
    public getAllSitesByCustomer(
        id: number,
        queryParams: QueryParams
    ): Promise<DeliverySite[]> {
        return this.getRequest(
            `${this.baseUrl}/customers/${id}/deliverySites/all`,
            queryParams
        );
    }

    public createCustomerSite(
        customerId: number,
        data: { [key: string]: any },
        queryParams?: QueryParams
    ): Promise<DeliverySite> {
        return this.postRequest(
            `${this.baseUrl}/customers/${customerId}/deliverySites`,
            data,
            queryParams
        );
    }

    public updateCustomerSite(
        customerId: number,
        siteId: number,
        data: { [key: string]: any },
        queryParams: QueryParams
    ): Promise<DeliverySite> {
        return this.patchRequest(
            `${this.baseUrl}/customers/${customerId}/deliverySites/${siteId}`,
            data,
            queryParams
        );
    }

    // get all accounts delivery sites (is_customer = true or false)
    public getAllDeliverySites(
        queryParams: QueryParams
    ): Promise<DeliverySite[]> {
        return this.getRequest(`${this.baseUrl}/deliverySites`, queryParams);
    }
}
