import { AccountTag } from '../models/account-tag';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IdAccountService } from './id-account.service';
import { ApiService } from './api.service';
import { DialogService } from 'primeng/dynamicdialog';
import { QueryParams } from '../models/query-params';
import { Tag } from '../models/tag';

@Injectable({
    providedIn: 'root',
})
export class AccountTagService extends ApiService {
    private baseUrl: string;
    private id: number;

    constructor(
        public IdAccountService: IdAccountService,
        public http: HttpClient,
        protected dialogService: DialogService
    ) {
        super(http, dialogService);
        this.id = this.IdAccountService.getIdAccountService();
        this.baseUrl = 'api/thot/accounts/' + this.id + '/tags/account_tags';
    }

    public getAccountTag(queryParams: QueryParams): Promise<Tag[]> {
        return this.getRequest(this.baseUrl, queryParams);
    }

    public createAccountTag(
        data: { [key: string]: any },
        queryParams?: QueryParams
    ): Promise<Tag> {
        return this.postRequest(this.baseUrl, data, queryParams);
    }
}
