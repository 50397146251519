import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Vehicle } from '../models/vehicle';

@Injectable()
export class SharedService {
    public headerVehicle = new BehaviorSubject(null);
    public headerWarehouse = new BehaviorSubject(null);
    public headerProvider = new BehaviorSubject(null);
    public headerPhysicalSupport = new BehaviorSubject(null);
    public headerItem = new BehaviorSubject(null);
    public menuDisplay = new BehaviorSubject(null);
    public menuVisibility: boolean = false;

    setHeaderVehicle(vehicle: Vehicle) {
        this.headerVehicle.next(vehicle);
    }

    setWarehouseHeader(designation) {
        this.headerWarehouse.next(designation);
    }

    setProviderHeader(designation) {
        this.headerProvider.next(designation);
    }

    setPhysicalSupportHeader(name) {
        this.headerPhysicalSupport.next(name);
    }

    setItemHeader(name) {
        this.headerItem.next(name);
    }

    clearHeaderInfo() {
        this.headerVehicle.next(null);
        this.headerWarehouse.next(null);
        this.headerProvider.next(null);
        this.headerPhysicalSupport.next(null);
        this.headerItem.next(null);
    }

    changeMenuDisplay() {
        this.menuVisibility = !this.menuVisibility;
        this.menuDisplay.next(this.menuVisibility);
    }

    openMenu() {
        this.menuVisibility = true;
        this.menuDisplay.next(this.menuVisibility);
    }
}
