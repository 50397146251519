import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Account } from 'src/app/models/account';
import { QueryParams } from 'src/app/models/query-params';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
    providedIn: 'root',
})
export class ProviderContactAccountService extends ApiService {
    baseUrl: string;
    accountId: number;

    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService
    ) {
        super(http, dialogService);
        this.baseUrl = 'api/thot/provider_contacts';
    }

    public getProviderContactAccounts(
        providerContactId: number,
        queryParams?: QueryParams
    ): Promise<Account[]> {
        return this.getRequest(
            `${this.baseUrl}/${providerContactId}/accounts`,
            queryParams
        );
    }
}
