<app-modal-header [customIcon]="headerCustomIcon" [icon]="headerIcon" [src]="headerSrc" [header]="headerTitle"
    (onClose)="Headerclose()" [endTemplate]="headerEndTemplate" [useClose]="headerUseClose"></app-modal-header>

<div [ngClass]="!isNotMargin ? 'container-dialog' : 'container-small-margin'">
    <div class="content-dialog">
        <ng-container [ngTemplateOutlet]="contentModalTemplate"></ng-container>
    </div>
</div>

<app-modal-buttons *ngIf="useModalButton" (onCancelClick)="cancelClick()" (onConfirmClick)="confirmClick()"
    [confirmLabel]="footerConfirmLabel" [cancelLabel]="footerCancelLabel" [beforeTemplate]="footerBeforeTemplate"
    [betweenTemplate]="footerBetweenTemplate" [afterTemplate]="footerAfterTemplate" [useSubmit]="footerUseSubmit"
    [displayConfirm]="footerDisplayConfirm" [disableConfirm]="footerDisableConfirm" [icon]="footerConfirmIcon"
    [typeConfirm]="footerTypeConfirm" [displayCancel]="footerDisplayCancel" [disableCancel]="footerDisableCancel"
    [tooltipCancel]="footerTooltipCancel">
</app-modal-buttons>