import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, mergeMap, retryWhen } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { IdAccountService } from './id-account.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public userSubject: BehaviorSubject<User>;
    public user: Observable<User>;

    constructor(
        private router: Router,
        private http: HttpClient,
        private IdAccountService: IdAccountService
    ) {
        this.userSubject = new BehaviorSubject<User>(null);
        this.user = this.userSubject.asObservable();
    }

    public get currentUser(): User {
        return this.userSubject.value;
    }

    private formatUserDisplayName(user: User): string {
        return user.first_name && user.last_name
            ? user.first_name + ' ' + user.last_name
            : user.username;
    }

    public getUser(userId: number): Observable<User> {
        return this.http
            .get<User>(`${environment.apiHost}/api/thot/users/${userId}/`)
            .pipe(
                map((user) => {
                    user.displayName = this.formatUserDisplayName(user);
                    return user;
                })
            );
    }

    public login(username: string, password: string): Observable<User> {
        return this.http
            .post<any>(
                `${environment.apiHost}/api/login/`,
                { username, password },
                { withCredentials: true }
            )
            .pipe(
                map((resp) => {
                    const jwt = jwt_decode(resp.access) as any;
                    const user = new User({
                        id: jwt.user_id,
                        token: resp.access,
                        refreshToken: resp.refresh,
                    });
                    this.userSubject.next(user); //mandatory to perform the getUser with good token
                    return user;
                })
            );
    }

    public isLoggedIn(): boolean {
        if (this.currentUser) {
            // logged in so return true
            return true;
        } else {
            // not logged in so return false
            return false;
        }
    }

    public logout() {
        const currentUser = JSON.parse(
            localStorage.getItem('currentUser')
        ) as User;
        const refreshToken = currentUser?.refreshToken;
        if (refreshToken) {
            this.http
                .post<any>(`${environment.apiHost}/api/logout/`, {
                    refresh: refreshToken,
                })
                .subscribe();
            this.stopRefreshTokenTimer();
            this.nukeLocalStorage();
            this.IdAccountService.clearAccount();
            this.userSubject.next(null);
            this.router.navigate(['/login']);
            window.location.reload();
        }
    }

    public nukeLocalStorage(): void {
        localStorage.removeItem('currentUser');
        localStorage.removeItem('tour-listing-endDate');
        localStorage.removeItem('tour-listing-startDate');
        localStorage.removeItem('tour-listing-state');
        localStorage.removeItem('tour-assignment-filter');
        localStorage.removeItem('tour-assignment');
        localStorage.removeItem('endDate');
        localStorage.removeItem('startDate');
        localStorage.removeItem('order-filter');
        localStorage.removeItem('sortDateTour');
        localStorage.removeItem('sortNameTour');
        localStorage.removeItem('filterDataTour');
    }

    public refreshToken(): Observable<User | null> {
        let currentUser = JSON.parse(
            localStorage.getItem('currentUser')
        ) as User;
        if (!currentUser) {
            this.userSubject.next(null);
            return of(null);
        } else {
            const refreshToken = currentUser.refreshToken;
            return this.http
                .post<any>(`${environment.apiHost}/api/token/refresh/`, {
                    refresh: refreshToken,
                })
                .pipe(
                    retryWhen((error) =>
                        error.pipe(
                            mergeMap((response) => {
                                if (response.status === 401) {
                                    throw { error: response };
                                }
                                return of(response).pipe(delay(10000));
                            })
                        )
                    ),
                    map((resp) => {
                        let jwt = jwt_decode(resp.access) as any;
                        (currentUser.id = jwt.user_id),
                            (currentUser.token = resp.access),
                            (currentUser.refreshToken = resp.refresh);
                        localStorage.setItem(
                            'currentUser',
                            JSON.stringify(currentUser)
                        );
                        this.userSubject.next(new User(currentUser));
                        this.startRefreshTokenTimer();
                        return currentUser;
                    })
                );
        }
    }

    // helper methods

    private refreshTokenTimeout: any;

    public startRefreshTokenTimer() {
        let currentUser = JSON.parse(
            localStorage.getItem('currentUser')
        ) as User;
        let jwt = jwt_decode(currentUser.token) as any;

        const expires = new Date(jwt.exp * 1000);
        const timeout = expires.getTime() - Date.now() - 5 * 60 * 1000;
        this.refreshTokenTimeout = setTimeout(() => {
            this.refreshToken().subscribe(
                (data) => {},
                (error) => {
                    if (error.status === 401) {
                        this.logout();
                    }
                }
            );
        }, timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
}
