/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { ModalHeaderComponent } from '../modal-header/modal-header.component';
import { ModalButtonsComponent } from '../modal-buttons/modal-buttons.component';
import { CommonModule } from '@angular/common';
import { ButtonTypeEnum } from 'src/app/enums/button-type';

@Component({
    standalone: true,
    selector: 'app-generic-modal',
    templateUrl: './generic-modal.component.html',
    styleUrls: ['./generic-modal.component.scss'],
    imports: [ModalButtonsComponent, ModalHeaderComponent, CommonModule],
})
export class GenericModalComponent {
    buttonType = ButtonTypeEnum;

    // FIELDS MODAL HEADER
    @Input() headerEndTemplate?: TemplateRef<any>;
    @Input() headerTitle: string = '';
    @Input() headerIcon: string;
    @Input() headerCustomIcon: boolean = false;
    @Input() headerSrc: string;
    @Input() headerUseClose: boolean = true;

    @Output() onHeaderClose: EventEmitter<void> = new EventEmitter();

    // FIELDS MODAL CONTAINER
    @Input() isNotMargin: boolean = false;
    @Input() contentModalTemplate: TemplateRef<any>;
    @Input() useModalButton: boolean = true;

    // FIELDS MODAL BUTTONS
    @Input() footerTypeConfirm: ButtonTypeEnum = this.buttonType.secondary;
    @Input() footerConfirmIcon: string = 'check';
    @Input() footerConfirmLabel: string = 'Enregistrer';
    @Input() footerCancelLabel: string = 'Annuler';
    @Input() footerBeforeTemplate?: TemplateRef<any>;
    @Input() footerBetweenTemplate?: TemplateRef<any>;
    @Input() footerAfterTemplate?: TemplateRef<any>;
    @Input() footerUseSubmit: boolean = true;
    @Input() footerDisplayConfirm: boolean = true;
    @Input() footerDisableConfirm: boolean = false;
    @Input() footerDisplayCancel: boolean = true;
    @Input() footerDisableCancel: boolean = false;
    @Input() footerTooltipCancel: string;

    @Output() onFooterCancelClick: EventEmitter<void> =
        new EventEmitter<void>();
    @Output() onFooterConfirmClick: EventEmitter<void> =
        new EventEmitter<void>();

    constructor() {}

    Headerclose(): void {
        this.onHeaderClose.emit();
    }

    cancelClick(): void {
        this.onFooterCancelClick.emit();
    }

    confirmClick(): void {
        this.onFooterConfirmClick.emit();
    }
}
