<div>
    <span class="captionElements p-input-icon-left p-ml-auto fit-content">
        <i class="pi pi-search custom-search-icon"></i>
        <input
            pInputText
            #searchGlobal
            [type]="buttonEnum.text"
            [(ngModel)]="searchText"
            placeholder="Rechercher"
            (input)="filterGlobal($event, 'contains')"
        />
    </span>
</div>
