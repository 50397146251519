/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CountriesService } from 'src/app/services/countries.service';
import Utils from '../utils';

@Component({
    standalone: true,
    selector: 'app-country-list',
    imports: [DropdownModule, FormsModule, CommonModule],
    templateUrl: './country-list.component.html',
    styleUrls: ['./country-list.component.scss'],
})
export class CountryListComponent implements OnInit {
    @Input() selectedCountryCode: any;
    @Input() countryToSelect: string; // country code to select
    @Input() disabled: boolean = false;
    @Output() onChanged = new EventEmitter();
    selectedCountry: any;
    codes = ['FR', 'MQ', 'GP', 'CI', 'LU'];

    countries = [];

    constructor(private countriesService: CountriesService) {}

    ngOnInit(): void {
        this.countriesService.getCountryList().then((countryList) => {
            this.countries = countryList.filter((c) =>
                this.codes.includes(c.cca2)
            );

            if (!Utils.isNotNil(this.selectedCountryCode)) {
                this.selectedCountry = this.countries.find(
                    (c) => c.cca2 === 'FR'
                );
            } else {
                this.selectedCountry = this.countries.find(
                    (c) => c.cca2 === this.selectedCountryCode
                );
            }
        });
    }

    public change(e) {
        this.onChanged.emit({
            codeCountry: e.value.cca2,
            countryPhone: e.value.idd.root + e.value.idd.suffixes[0],
        });
    }
}
