<div class="p-d-flex">
    <app-apollo-button
        *ngIf="useFilter"
        icon="filter"
        [disabled]="disableFilter"
        [tooltip]="filterTooltip"
        [tooltipPosition]="filterTooltipPosition"
        (onClick)="onfilterButtonClick()"
    ></app-apollo-button>
    <app-apollo-button
        icon="filter-slash"
        [disabled]="disableClear"
        [tooltip]="clearTooltip"
        [tooltipPosition]="clearTooltipPosition"
        (onClick)="onClearButtonClick()"
        [type]="buttonEnum.outlined"
        [ngClass]="useClear ? '' : 'hidden'"
    ></app-apollo-button>
</div>
