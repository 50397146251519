import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CardModule } from 'primeng/card';

import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { GenericModalComponent } from 'src/app/components/shared/generic-modal/generic-modal.component';
import UtilsModal from 'src/app/components/shared/utils-modal';

@Component({
    standalone: true,
    selector: 'app-modal-error',
    imports: [CommonModule, CardModule, GenericModalComponent],
    templateUrl: './modal-error.component.html',
    styleUrls: ['./modal-error.component.scss'],
})
export class ModalErrorComponent {
    public err: string;
    public contactInfo =
        ' Veuillez contacter le service informatique aux 06.02.03.77.84';
    public header: string;

    public utilsModal = UtilsModal;

    constructor(
        private config: DynamicDialogConfig,
        public ref: DynamicDialogRef
    ) {
        this.err = this.config.data.err;
        this.header = this.config.data.header;
    }
}
