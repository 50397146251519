import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProviderContactPermissionService } from '../services/provider-contact-permission.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import Utils from 'src/app/components/shared/utils';

@Injectable({ providedIn: 'root' })
export class ProviderPermissionsGuard implements CanActivate {
    constructor(
        private providerContactPermissionService: ProviderContactPermissionService,
        private auth: AuthenticationService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (Utils.isNotNil(this.auth.currentUser.contact)) {
            return this.providerContactPermissionService
                .hasPermission(next)
                .then((val) => {
                    if (val) {
                        return true;
                    } else {
                        this.router.navigate(['./provider-main']);
                        return false;
                    }
                });
        } else if (Utils.isNotNil(this.auth.currentUser.agent)) {
            this.router.navigate(['./main']);
            return false;
        } else {
            this.router.navigate(['./login']);
            return false;
        }
    }
}
