import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { PermissionService } from '../services/permission.service';
import { AuthenticationService } from '../services/authentication.service';
import Utils from '../components/shared/utils';

@Injectable({ providedIn: 'root' })
export class PermissionsGuard implements CanActivate {
    constructor(
        private permissionService: PermissionService,
        private auth: AuthenticationService,
        private router: Router
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (Utils.isNotNil(this.auth.currentUser.agent)) {
            return this.permissionService.hasPermission(next).then((val) => {
                if (val) {
                    return true;
                } else {
                    this.router.navigate(['./main']);
                    return false;
                }
            });
        } else if (Utils.isNotNil(this.auth.currentUser.contact)) {
            this.router.navigate(['./provider-main']);
            return false;
        } else {
            this.router.navigate(['./login']);
            return false;
        }
    }
}
