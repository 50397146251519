import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { HistoryEntry } from 'src/app/models/history-entry';
import { QueryParams } from 'src/app/models/query-params';
import { TransportOrder } from 'src/app/models/transport-order';
import { AddProviderComment } from 'src/app/services/add-provider-comment';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { Comment } from 'src/app/models/comment';

@Injectable({
    providedIn: 'root',
})
export class ProviderContactTransportOrderService
    extends ApiService
    implements AddProviderComment
{
    baseUrl: string;
    accountId: number;

    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService
    ) {
        super(http, dialogService);
        this.baseUrl = 'api/thot/provider_contacts';
    }

    public getProviderContactTransportOrders(
        providerContactId: number,
        queryParams: QueryParams
    ): Promise<TransportOrder[]> {
        return this.getRequest(
            `${this.baseUrl}/${providerContactId}/transport_orders`,
            queryParams
        );
    }

    public getProviderTransportOrdersBoard(
        providerContactId: number,
        filters
    ): Promise<TransportOrder[]> {
        const queryParams: QueryParams = {
            fields: [
                'id',
                'customer_ordering.designation',
                'warehouse_ordering.designation',
                'order_number',
                'tags_stringify',
                'quantity',
                'quantity_details',
                'unit',
                'transporter.designation',
                'transporter.id',
                'available_date',
                'transport_mode.label',
                'status',
                'loading_site.zipcode',
                'loading_site.city',
                'loading_site.designation',
                'loadout_site.zipcode',
                'loadout_site.city',
                'loadout_site.designation',
                'documents',
                'priority',
                'is_divers',
                'loading_mode',
                'loadout_mode',
                'account.company_name',
            ],
            expand: [
                'customer_ordering',
                'warehouse_ordering',
                'transporter',
                'transport_mode',
                'loading_site',
                'loadout_site',
                'loading_mode',
                'loadout_mode',
                'account',
            ],
            filters: filters,
        };
        return (
            this.getRequest(
                `${this.baseUrl}/${providerContactId}/transport_orders/board`,
                queryParams
            ) as Promise<TransportOrder[]>
        ).then((transportOrders) => {
            transportOrders = transportOrders.map((transportOrder) => {
                let documents_urls = [];
                transportOrder.documents.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    documents_urls.push(document);
                });
                transportOrder.documents = documents_urls;
                return transportOrder;
            });
            return transportOrders;
        });
    }

    public getProviderTransportOrder(
        providerContactId: number,
        id: number,
        queryParams: QueryParams
    ): Promise<TransportOrder> {
        return (
            this.getDetailRequest(
                `${this.baseUrl}/${providerContactId}/transport_orders/${id}`,
                queryParams
            ) as Promise<TransportOrder>
        ).then((transportOrder) => {
            if (transportOrder.documents) {
                const documents = [];
                transportOrder.documents.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    documents.push(document);
                });
                transportOrder.documents = documents;
            }
            return transportOrder;
        });
    }

    public patchProviderTransportOrder(
        providerContactId: number,
        id: number,
        data: { [key: string]: any }
    ): Promise<TransportOrder> {
        return this.patchRequest(
            `${this.baseUrl}/${providerContactId}/transport_orders/${id}`,
            data
        );
    }

    public addProviderComment(
        providerContactId: number,
        id: number,
        data: { [key: string]: any }
    ): Promise<Comment> {
        return this.postRequest(
            `${this.baseUrl}/${providerContactId}/transport_orders/${id}/addComment`,
            data
        );
    }

    public getProviderTransportOrderHistory(
        providerContactId: number,
        id: number
    ): Promise<HistoryEntry[]> {
        return this.getRequest(
            `${this.baseUrl}/${providerContactId}/transport_orders/${id}/history`
        );
    }
}
