<div class="modal-header">
    <i *ngIf="icon" class="{{ displayIcon }}"></i>
    <img
        style="max-width: 30px"
        *ngIf="customIcon"
        alt="maintenance"
        src="{{ srcIcon }}"
    />
    <span class="modal-title">{{ header }}</span>
    <ng-container [ngTemplateOutlet]="endTemplate"></ng-container>
    <app-apollo-button
        *ngIf="useClose"
        icon="times"
        [type]="buttonType.text"
        (onClick)="close()"
    ></app-apollo-button>
</div>
