import { DynamicDialogRef } from 'primeng/dynamicdialog';

export default class UtilsModal {
    public static close(ref: DynamicDialogRef, element?: any) {
        if (element) {
            ref.close(element);
        } else {
            ref.close();
        }
    }
}
