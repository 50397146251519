import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { IdAccountService } from './id-account.service';

@Injectable({
    providedIn: 'root',
})
export class HereService extends ApiService {
    baseUrl: string;
    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        private IdAccountService: IdAccountService
    ) {
        super(http, dialogService);
        this.baseUrl = `api/thot/accounts/${this.IdAccountService.getIdAccountService()}`;
    }

    // calculate route throw here between two waypoints
    public hereCalculateRoute(data: any): Promise<any> {
        return this.postRequest(
            `${this.baseUrl}/hermes/here/calculateRoute`,
            data
        );
    }

    public getHereRouting(data: {
        [key: string]: any;
    }): Promise<{ [key: string]: any }> {
        return this.postRequest(`${this.baseUrl}/iris/here/routing`, data);
    }
}
