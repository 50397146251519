import 'reflect-metadata';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {
    HttpClient,
    HttpClientModule,
    HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPermissionsModule } from 'ngx-permissions';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { FilterService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { JwtInitializer } from './services/app.initializer';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { AuthenticationService } from './services/authentication.service';
import { DeliverySiteService } from './services/delivery-site.service';
import { AgentActivityReportService } from './services/agent-activity-report.service';
import { HereService } from './services/here.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ImageModule } from 'primeng/image';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SharedService } from './services/shared.service';
import { IdAccountService } from './services/id-account.service';
import { AgentService } from './services/agent.service';
import { AgentAccountService } from './services/agent-account.service';
import { CustomerService } from './services/customer.service';
import { PhysicalSupportService } from './services/physical-support.service';
import { CountriesService } from './services/countries.service';
import { CategoriesService } from './services/categories.service';
import { UserIssuesService } from './services/user-issues.service';
import { AccountTagService } from './services/account-tag.service';
import { CountryListComponent } from './components/shared/country-list/country-list.component';
import { ListHeaderComponent } from './components/shared/list-header/list-header.component';
import { MaintenanceRequestService } from './services/maintenance-request.service';
import { ProviderContactTransportOrderService } from './provider/services/provider-contact-transport-order.service';
import { ProviderContactPermissionService } from './provider/services/provider-contact-permission.service';
import { ProviderContactAccountService } from './provider/services/provider-contact-account.service';
import { ProviderContactMaintenanceRequestService } from './provider/services/provider-contact-maintenance-request.service';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from 'src/environments/environment';
import { CDK_DRAG_CONFIG } from '@angular/cdk/drag-drop';
import { FormGroupDirective } from '@angular/forms';
import { ItemMovementService } from './services/item-movement.service';
import { ModalItemAccountProviderComponent } from './modals/modal-item-account-provider/modal-item-account-provider.component';

registerLocaleData(localeFr, 'fr');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

const DragConfig = {
    dragStartThreshold: 0,
    pointerDirectionChangeThreshold: 5,
    zIndex: 5000,
};

@NgModule({
    declarations: [AppComponent],
    providers: [
        FilterService,
        MessageService,
        ConfirmationService,
        DialogService,
        AccountTagService,
        DeliverySiteService,
        CustomerService,
        CountriesService,
        CategoriesService,
        UserIssuesService,
        AgentService,
        AgentAccountService,
        IdAccountService,
        PhysicalSupportService,
        AgentActivityReportService,
        HereService,
        SharedService,
        MaintenanceRequestService,
        ItemMovementService,
        ProviderContactAccountService,
        ProviderContactMaintenanceRequestService,
        ProviderContactTransportOrderService,
        ProviderContactPermissionService,
        FormGroupDirective,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        {
            provide: APP_INITIALIZER,
            useFactory: JwtInitializer,
            multi: true,
            deps: [AuthenticationService],
        },
        { provide: CDK_DRAG_CONFIG, useValue: DragConfig },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        // { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ],
    bootstrap: [AppComponent],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ImageModule,
        PdfViewerModule,
        ToastModule,
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        CountryListComponent,
        ListHeaderComponent,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
    ],
})
export class AppModule {}
