import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { Account } from 'src/app/models/account';
import { HistoryEntry } from 'src/app/models/history-entry';
import { MaintenanceRequest } from 'src/app/models/maintenance-request';
import { MaintenanceRequestCost } from 'src/app/models/maintenance-request-cost';
import { PaginatedMaintenanceRequest } from 'src/app/models/paginated-maintenance-request';
import { PaginatedParams } from 'src/app/models/paginated-params';
import { QueryParams } from 'src/app/models/query-params';
import { AddProviderComment } from 'src/app/services/add-provider-comment';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { Comment } from 'src/app/models/comment';

@Injectable({
    providedIn: 'root',
})
export class ProviderContactMaintenanceRequestService
    extends ApiService
    implements AddProviderComment
{
    baseUrl: string;
    accountId: number;

    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService
    ) {
        super(http, dialogService);
        this.baseUrl = 'api/thot/provider_contacts';
    }

    public getProviderContactMaintenanceRequests(
        providerContactId: number,
        filters
    ): Promise<MaintenanceRequest[]> {
        let queryParams: QueryParams = {
            fields: [
                'id',
                'created',
                'content',
                'vehicle.id',
                'vehicle.registration',
                'vehicle.park_code',
                'provider.id',
                'title',
                'status',
                'repair_date',
                'repair_time',
                'end_repair_date',
                'end_repair_time',
                'documents',
                'maintenance_type.label',
                'maintenance_subtype.label',
                'limit_date',
                'priority',
                'status_changed',
                'done_date',
            ],
            expand: [
                'vehicle',
                'provider',
                'maintenance_type',
                'maintenance_subtype',
            ],
            filters: filters,
        };

        return (
            this.getRequest(
                `${this.baseUrl}/${providerContactId}/maintenance_requests`,
                queryParams
            ) as Promise<MaintenanceRequest[]>
        ).then((requests) => {
            requests.forEach((request) => {
                let document_urls = [];
                request.documents?.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    document_urls.push(document);
                });
                request.documents = document_urls;
            });
            return requests;
        });
    }

    public getPaginatedProviderContactMaintenanceRequests(
        providerContactId: number,
        queryParams: QueryParams,
        paginatedParams: PaginatedParams
    ): Promise<PaginatedMaintenanceRequest> {
        return (
            this.paginatedRequest(
                `${this.baseUrl}/${providerContactId}/maintenance_requests`,
                queryParams,
                paginatedParams
            ) as Promise<PaginatedMaintenanceRequest>
        ).then((requests) => {
            requests.results = requests.results?.map((result) => {
                let document_urls = [];
                result.documents?.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    document_urls.push(document);
                });
                result.documents = document_urls;
                return result;
            });
            return requests;
        });
    }

    public getProviderMaintenanceRequest(
        providerContactId: number,
        id: number,
        queryParams: QueryParams
    ): Promise<MaintenanceRequest> {
        return (
            this.getDetailRequest(
                `${this.baseUrl}/${providerContactId}/maintenance_requests/${id}`,
                queryParams
            ) as Promise<MaintenanceRequest>
        ).then((maintenanceRequest) => {
            if (maintenanceRequest.documents) {
                const documents = [];
                maintenanceRequest.documents.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    documents.push(document);
                });
                maintenanceRequest.documents = documents;
            }
            return maintenanceRequest;
        });
    }

    public getProviderMaintenanceRequestsExport(
        providerContactId: number,
        queryParams: QueryParams
    ) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let httpParams = this.setHttpParams(queryParams);
        return this.http.get<any>(
            `${environment.apiHost}/${this.baseUrl}/${providerContactId}/maintenance_requests/export`,
            {
                headers: httpHeaders,
                params: httpParams,
                responseType: 'blob' as 'json',
            }
        );
    }

    public createProviderMaintenanceRequest(
        providerContactId: number,
        data: {
            [key: string]: any;
        }
    ): Promise<MaintenanceRequest> {
        const queryParams: QueryParams = {
            fields: [
                'id',
                'created',
                'content',
                'documents',
                'vehicle.registration',
                'provider.id',
                'provider.designation',
                'title',
                'guilty_type',
                'guilty_agent',
                'guilty_reason',
                'status',
                'repair_date',
            ],
            expand: ['vehicle', 'provider'],
        };
        return (
            this.postRequest(
                `${this.baseUrl}/${providerContactId}/maintenance_requests`,
                data,
                queryParams
            ) as Promise<MaintenanceRequest>
        ).then((maintenanceRequest) => {
            if (maintenanceRequest?.documents) {
                const documents = [];
                maintenanceRequest.documents.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    documents.push(document);
                });
                maintenanceRequest.documents = documents;
            }
            return maintenanceRequest;
        });
    }

    public updateProviderMaintenanceRequest(
        providerContactId: number,
        id: number,
        data: { [key: string]: any },
        queryParams?: QueryParams
    ): Promise<MaintenanceRequest> {
        return (
            this.patchRequest(
                `${this.baseUrl}/${providerContactId}/maintenance_requests/${id}`,
                data,
                queryParams
            ) as Promise<MaintenanceRequest>
        ).then((maintenanceRequest) => {
            if (maintenanceRequest?.documents) {
                const documents = [];
                maintenanceRequest.documents.forEach((document) => {
                    document.file = `${environment.apiHost}/media/filer/${document.file}`;
                    documents.push(document);
                });
                maintenanceRequest.documents = documents;
            }

            return maintenanceRequest;
        });
    }

    public updateProviderMaintenanceRequests(
        providerContactId: number,
        ids: number[],
        patchData: { [key: string]: any },
        queryParams?: QueryParams
    ): Promise<MaintenanceRequest[]> {
        const data = {
            ids: ids,
            data: patchData,
        };
        return this.patchRequest(
            `${this.baseUrl}/${providerContactId}/maintenance_requests/multiple_update`,
            data,
            queryParams
        );
    }

    public addProviderComment(
        providerContactId: number,
        id: number,
        data: { [key: string]: any }
    ): Promise<Comment> {
        return this.postRequest(
            `${this.baseUrl}/${providerContactId}/maintenance_requests/${id}/addComment`,
            data
        );
    }

    public getProviderMaintenanceRequestHistory(
        providerContactId: number,
        maintenanceRequestId: number
    ): Promise<HistoryEntry[]> {
        return this.getRequest(
            `${this.baseUrl}/${providerContactId}/maintenance_requests/${maintenanceRequestId}/history`
        );
    }

    public getProviderMaintenanceRequestCalendar(
        providerContactId: number,
        queryParams: QueryParams
    ): Promise<MaintenanceRequest[]> {
        return this.getRequest(
            `${this.baseUrl}/${providerContactId}/maintenance_requests/calendar`,
            queryParams
        );
    }

    public sendProviderMaintenanceRequestEmail(
        providerContactId: number,
        maintenanceRequestId: number,
        data: { [key: string]: any }
    ): Promise<any> {
        return this.postRequest(
            `${this.baseUrl}/${providerContactId}/maintenance_requests/${maintenanceRequestId}/send_email`,
            data
        );
    }

    public updateProviderMaintenanceRequestCosts(
        providerContactId: number,
        maintenanceRequestCostId: number,
        data: { [key: string]: any }
    ): Promise<MaintenanceRequestCost> {
        return this.patchRequest(
            `${this.baseUrl}/${providerContactId}/maintenance_request_costs/${maintenanceRequestCostId}`,
            data
        );
    }

    // response blob
    public downloadMaintenanceRequestsPDF(
        providerContactId: number,
        date: string,
        account: Account
    ): Observable<Blob> {
        let dateParam = `?date=${date}&accountId=${account.id}`;
        return this.http.get(
            `${environment.apiHost}/${this.baseUrl}/${providerContactId}/maintenance_requests/pdf/${dateParam}`,
            {
                responseType: 'blob',
            }
        );
    }
}
