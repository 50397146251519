import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { IndustrialCategory } from '../models/industrial-category';
import { QueryParams } from '../models/query-params';
import { ApiService } from './api.service';
import { IdAccountService } from './id-account.service';

@Injectable({
    providedIn: 'root',
})
export class CategoriesService extends ApiService {
    private id: number;
    private baseUrl: string;
    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        private IdAccountService: IdAccountService
    ) {
        super(http, dialogService);
        this.id = this.IdAccountService.getIdAccountService();
        this.baseUrl = 'api/thot/accounts/' + this.id + '/hestia';
    }

    public getCategories(
        queryParams: QueryParams
    ): Promise<IndustrialCategory[]> {
        return this.getRequest(`api/thot/categories`, queryParams);
    }

    public updateCategories(
        idCompany: number,
        data: { [key: string]: any }
    ): Promise<IndustrialCategory[]> {
        return this.patchRequest(
            `${this.baseUrl}/customers/${idCompany}/updateCategories`,
            data
        );
    }
}
