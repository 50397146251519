import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApolloButtonComponent } from '../apollo-button/apollo-button.component';
import { ButtonTypeEnum } from 'src/app/enums/button-type';

@Component({
    selector: 'app-modal-header',
    standalone: true,
    templateUrl: './modal-header.component.html',
    styleUrls: ['./modal-header.component.scss'],
    imports: [CommonModule, ApolloButtonComponent],
})
export class ModalHeaderComponent implements OnChanges {
    @Input() endTemplate?: TemplateRef<any>;
    @Input() header: string = '';
    @Input() icon: string;
    @Input() customIcon: boolean = false;
    @Input() useClose: boolean = true;
    @Input() src: string;
    // eslint-disable-next-line @angular-eslint/no-output-on-prefix
    @Output() onClose: EventEmitter<void> = new EventEmitter();

    buttonType = ButtonTypeEnum;
    public displayIcon: string;
    public srcIcon: string;

    constructor() {}

    ngOnChanges() {
        if (!this.customIcon && this.icon) {
            this.displayIcon = `pi pi-${this.icon}`;
        }
        if (this.customIcon && this.src) {
            this.srcIcon = `/assets/icons/${this.src}`;
        }
    }

    close(): void {
        this.onClose.emit();
    }
}
