import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { LabelledEntityWithCode } from '../models/labelled-entity-with-code';

@Injectable({
    providedIn: 'root',
})
export class CountriesService extends ApiService {
    private baseUrl: string;
    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService
    ) {
        super(http, dialogService);
        this.baseUrl = `api/thot/generics/countries`;
    }

    public getCountryList(): Promise<any> {
        return this.http.get(`https://restcountries.com/v3.1/all`).toPromise();
    }

    public getAllCountries(): Promise<LabelledEntityWithCode[]> {
        return this.getRequest(this.baseUrl);
    }
}
