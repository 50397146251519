import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarModule } from 'primeng/toolbar';
import { DateSelectorComponent } from '../date-selector/date-selector.component';
import { SearchComponentComponent } from '../search-component/search-component.component';
import { Table } from 'primeng/table';
import { ApolloButtonComponent } from '../apollo-button/apollo-button.component';
import { ButtonTypeEnum } from 'src/app/enums/button-type';
import { FilterButtonsComponent } from '../filter-buttons/filter-buttons.component';
import UtilsFilter from '../utils-filter';
import Utils from '../utils';

@Component({
    selector: 'app-list-header',
    standalone: true,
    imports: [
        CommonModule,
        ToolbarModule,
        FilterButtonsComponent,
        DateSelectorComponent,
        SearchComponentComponent,
        ApolloButtonComponent,
    ],
    templateUrl: './list-header.component.html',
    styleUrls: ['./list-header.component.scss'],
})
export class ListHeaderComponent {
    // TOOLBAR START
    @Input() startTemplate?: TemplateRef<any>;
    @Input() useCreate: boolean = true;
    @Input() disableCreate: boolean = false;
    @Input() createLabel: string = 'Ajouter';
    @Input() createTooltip: any;
    @Input() createTooltipPosition: any;

    @Output() createButtonClick: EventEmitter<void> = new EventEmitter();

    @Input() useFilter: boolean = true;
    @Input() disableFilter: boolean = false;
    @Input() filterTooltip: any;
    @Input() filterTooltipPosition: any;
    @Output() filterButtonClick: EventEmitter<void | any> = new EventEmitter();

    @Input() useClear: boolean = false;
    @Input() disableClear: boolean = false;
    @Input() clearTooltip: any;
    @Input() clearTooltipPosition: any;
    @Output() clearButtonClick: EventEmitter<void> = new EventEmitter();

    @Input() useExport: boolean = false;
    @Input() exportTooltip: string = 'Export XLSX';
    @Input() exportTooltipPosition: 'top' | 'bottom' | 'left' | 'right' =
        'bottom';
    @Input() exportIcon: string = 'file-excel';
    @Input() disableExport: boolean = false;
    @Output() exportButtonClick: EventEmitter<void | any> = new EventEmitter();

    // TOOLBAR CENTER
    @Input() centerTemplate?: TemplateRef<any>;
    @Input() useDate: boolean = true;
    @Input() dateSelectorClass: string;
    @Input() startDate?: Date;
    @Input() startDatePlaceholder: string;
    @Input() startDateTooltip: string;
    @Input() minStartDate?: Date;
    @Input() endDate?: Date;
    @Input() endDatePlaceholder: string;
    @Input() endDateTooltip: string;
    @Input() initialStart?: Date;
    @Input() initialEnd?: Date;

    @Output() startDateChange: EventEmitter<Date> = new EventEmitter();
    @Output() endDateChange: EventEmitter<Date> = new EventEmitter();

    @Output() OnStartDateChange: EventEmitter<Date> = new EventEmitter();
    @Output() OnEndDateChange: EventEmitter<Date> = new EventEmitter();

    // TOOLBAR END
    @Input() endTemplate?: TemplateRef<any>;
    @Input() useSearch: boolean = true;
    @Input() globalSearch?: string;
    @Input() table?: Table;

    @Output() globalSearchChange: EventEmitter<string> = new EventEmitter();

    @Output() OnChanges: EventEmitter<any> = new EventEmitter();

    // VAR COMPONENT
    public buttonEnum = ButtonTypeEnum;

    constructor() {}

    onStartClose(event: Date) {
        this.startDate = event;
        this.startDateChange.emit(this.startDate);
        this.OnStartDateChange.emit();
    }

    onEndClose(event: Date) {
        this.endDate = event;
        this.endDateChange.emit(this.endDate);
        this.OnEndDateChange.emit();
    }

    onCreateButtonClick() {
        this.createButtonClick.emit();
    }

    onSearchTextChange() {
        this.globalSearchChange.emit(this.globalSearch);
    }

    public onfilterButtonClick() {
        this.filterButtonClick.emit();
    }

    public onClearButtonClick() {
        this.clearButtonClick.emit();
        this.clear();
    }

    public clear() {
        this.table.lazy = false;
        let isEmitStart = this.clearStartDate();
        let isEmitEnd = this.clearEndDate();
        let isEmitSearch = this.clearGlobalSearch();
        if (isEmitStart) {
            this.startDateChange.emit(this.startDate);
        }
        if (isEmitEnd) {
            this.endDateChange.emit(this.endDate);
        }
        if (isEmitSearch) {
            this.globalSearchChange.emit(this.globalSearch);
        }
        this.clearTable();
    }

    public clearStartDate(): boolean {
        let isEmit: boolean = false;
        if (this.initialStart && this.initialStart !== this.startDate) {
            this.startDate = this.initialStart;
            isEmit = true;
        } else if (!this.initialStart) {
            if (Utils.isNotNil(this.startDate)) {
                this.startDate = null;
                isEmit = true;
            }
        }
        return isEmit;
    }

    public clearEndDate(): boolean {
        let isEmit: boolean = false;
        if (this.initialEnd && this.initialEnd !== this.endDate) {
            this.endDate = this.initialEnd;
            isEmit = true;
        } else if (!this.initialEnd) {
            if (Utils.isNotNil(this.endDate)) {
                this.endDate = null;
                isEmit = true;
            }
        }
        return isEmit;
    }

    public clearGlobalSearch(): boolean {
        let isEmit: boolean = false;
        if (this.useSearch && this.globalSearch) {
            this.globalSearch = null;
            isEmit = true;
        }
        return isEmit;
    }

    public clearTable() {
        UtilsFilter.clearTableFilterValues(this.table);
        this.table.lazy = true;
        this.table.reset();
    }

    public onExportExcel() {
        this.exportButtonClick.emit();
    }
}
