import { TeardownLogic } from 'rxjs';
import { AuthenticationService } from './authentication.service';

export function JwtInitializer(auth: AuthenticationService) {
    return () =>
        // ! FIXME resolve is expected to be a TearDownLogic value
        new Promise((resolve) => {
            // attempt to refresh token on app start up to auto authenticate
            auth.refreshToken()
                .subscribe()
                .add(resolve as TeardownLogic);
        });
}
