import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DeliverySite } from '../models/delivery-site';
import { PaginatedDeliverySites } from '../models/paginated-delivery-sites';
import { PaginatedParams } from '../models/paginated-params';
import { QueryParams } from '../models/query-params';
import { ApiService } from './api.service';
import { IdAccountService } from './id-account.service';

@Injectable({
    providedIn: 'root',
})
export class CustomerService extends ApiService {
    private id: number;
    private baseUrl: string;
    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        private IdAccountService: IdAccountService
    ) {
        super(http, dialogService);
        this.id = this.IdAccountService.getIdAccountService();
        this.baseUrl = 'api/thot/accounts/' + this.id + '/hestia';
    }

    public getPaginatedCustomers(
        queryParams: QueryParams,
        paginatedParams: PaginatedParams
    ): Promise<PaginatedDeliverySites> {
        return this.paginatedRequest(
            `${this.baseUrl}/customers`,
            queryParams,
            paginatedParams
        );
    }

    public getCustomers(queryParams?: QueryParams): Promise<DeliverySite[]> {
        return this.getRequest(`${this.baseUrl}/customers`, queryParams);
    }

    // ! Uses .pipe => Not compatible with current apiService, Find a solution for this
    public countCustomersBy(filters: {}): Observable<{ [key: string]: any }> {
        const queryParams = { filters: JSON.stringify(filters) };
        return this.http.get<{ [key: string]: any }>(
            `${environment.apiHost}/${
                this.baseUrl
            }/customers/find_by/?${new URLSearchParams(queryParams).toString()}`
        );
    }

    public getCustomer(
        id: number,
        queryParams: QueryParams
    ): Promise<DeliverySite> {
        return this.getDetailRequest(
            `${this.baseUrl}/customers/${id}`,
            queryParams
        );
    }

    public updateCustomer(
        id: number,
        data: { [key: string]: any },
        queryParams?: QueryParams
    ): Promise<DeliverySite> {
        return this.patchRequest(
            `${this.baseUrl}/customers/${id}`,
            data,
            queryParams
        );
    }

    public createCustomer(
        data: DeliverySite,
        queryParams?: QueryParams
    ): Promise<DeliverySite> {
        return this.postRequest(`${this.baseUrl}/customers`, data, queryParams);
    }

    public getCustomersExport(queryParams: QueryParams) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let httpParams = this.setHttpParams(queryParams);
        return this.http.get<any>(
            `${environment.apiHost}/${this.baseUrl}/customers/export/`,
            {
                headers: httpHeaders,
                params: httpParams,
                responseType: 'blob' as 'json',
            }
        );
    }
}
