import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { PhysicalSupport } from '../models/physical-support';
import { ApiService } from './api.service';
import { IdAccountService } from './id-account.service';
import { QueryParams } from '../models/query-params';
import { PaginatedParams } from '../models/paginated-params';
import { PaginatedPhysicalSupports } from '../models/paginated-physical-supports';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root',
})
export class PhysicalSupportService extends ApiService {
    private baseUrl: string;

    constructor(
        protected http: HttpClient,
        private IdAccountService: IdAccountService,
        protected dialogService: DialogService
    ) {
        super(http, dialogService);
        this.baseUrl = `api/thot/accounts/${this.IdAccountService.getIdAccountService()}/demeter`;
    }

    public getPhysicalSupport(
        id: number,
        queryParams: QueryParams
    ): Promise<PhysicalSupport> {
        return this.getDetailRequest(
            `${this.baseUrl}/supports/${id}`,
            queryParams
        );
    }

    public getPhysicalSupports(
        queryParams: QueryParams
    ): Promise<PhysicalSupport[]> {
        return this.getRequest(`${this.baseUrl}/supports`, queryParams);
    }

    public getPaginatedPhysicalSupports(
        queryParams: QueryParams,
        paginatedParams: PaginatedParams
    ): Promise<PaginatedPhysicalSupports> {
        return this.paginatedRequest(
            `${this.baseUrl}/supports`,
            queryParams,
            paginatedParams
        );
    }

    public createPhysicalSupport(data: {
        [key: string]: any;
    }): Promise<PhysicalSupport> {
        const queryParams: QueryParams = {
            fields: [
                'id',
                'code',
                'name',
                'is_numbered',
                'priority_rank',
                'is_active',
                'not_available',
                'stock',
            ],
            expand: ['support'],
        };
        return this.postRequest(
            `${this.baseUrl}/supports`,
            data,
            queryParams
        ) as Promise<PhysicalSupport>;
    }

    public patchPhysicalSupport(
        id: number,
        data: { [key: string]: any }
    ): Promise<PhysicalSupport> {
        return this.patchRequest(`${this.baseUrl}/supports/${id}`, data);
    }

    public getPhysicalSupportExport(queryParams: QueryParams) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let httpParams = this.setHttpParams(queryParams);
        return this.http.get<any>(
            `${environment.apiHost}/${this.baseUrl}/supports/export/`,
            {
                headers: httpHeaders,
                params: httpParams,
                responseType: 'blob' as 'json',
            }
        );
    }

    public findByPhysicalSupport(
        filters: {},
        id?: number
    ): Observable<{
        [key: string]: any;
    }> {
        const queryParams = { filters: JSON.stringify(filters) };
        return this.http.get<{ [key: string]: any }>(
            `${environment.apiHost}/${this.baseUrl}/supports${
                id ? '/' + id : ''
            }/find_by?${new URLSearchParams(queryParams).toString()} `
        );
    }

    public findByBarCodeAndIdNumber(
        filters: {},
        id?: number
    ): Observable<{
        [key: string]: any;
    }> {
        const queryParams = { bar_code: filters.toString() };
        return this.http.get<{ [key: string]: any }>(
            `${environment.apiHost}/${this.baseUrl}/supports${
                id ? '/' + id : ''
            }/find_by_bar_code_and_id_number?${new URLSearchParams(
                queryParams
            ).toString()} `
        );
    }
}
