export const environment = {
    production: false,
    apiHost: 'https://api-demo.solaris-bi.com',
    firebase: {
        apiKey: 'AIzaSyBbHaHtF1g4DRkCiL8yrUsVXSMQlp-BSFM',
        authDomain: 'apollo-firebase-f5c8a.firebaseapp.com',
        projectId: 'apollo-firebase-f5c8a',
        storageBucket: 'apollo-firebase-f5c8a.appspot.com',
        messagingSenderId: '123906496211',
        appId: '1:123906496211:web:69fe004c5b37f7903a6626',
        measurementId: 'G-3Z6YL1073C',
    },
};
