import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { IdAccountService } from './id-account.service';
import { QueryParams } from '../models/query-params';
import { PaginatedParams } from '../models/paginated-params';
import { PaginatedItemMovement } from '../models/paginated-item-movement';
import { environment } from 'src/environments/environment';
import { ItemMovement } from '../models/item-movement';

@Injectable({
    providedIn: 'root',
})
export class ItemMovementService extends ApiService {
    baseUrl: string;
    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        private IdAccountService: IdAccountService
    ) {
        super(http, dialogService);
        this.baseUrl = `api/thot/accounts/${this.IdAccountService.getIdAccountService()}/demeter/item_movement`;
    }

    public getItemMovement(
        id: number,
        queryParams: QueryParams
    ): Promise<ItemMovement> {
        return this.getDetailRequest(
            `${this.baseUrl}/${id}`,
            queryParams
        ) as Promise<ItemMovement>;
    }

    getItemMovements(queryParams: QueryParams): Promise<ItemMovement[]> {
        return this.getRequest(this.baseUrl, queryParams);
    }

    public getPaginatedItemMovements(
        queryParams: QueryParams,
        paginatedParams: PaginatedParams
    ): Promise<PaginatedItemMovement> {
        return this.paginatedRequest(
            `${this.baseUrl}`,
            queryParams,
            paginatedParams
        );
    }

    public getExport(queryParams: QueryParams) {
        const httpHeaders = new HttpHeaders({
            'Content-Type': 'application/json',
        });
        let httpParams = this.setHttpParams(queryParams);
        return this.http.get<any>(
            `${environment.apiHost}/${this.baseUrl}/export/`,
            {
                headers: httpHeaders,
                params: httpParams,
                responseType: 'blob' as 'json',
            }
        );
    }

    public createItemMovement(data: any): Promise<ItemMovement> {
        const queryParams: QueryParams = {
            fields: ['id'],
        };
        return this.postRequest(this.baseUrl, data, queryParams);
    }

    public multipleReserved(ids: number[]): Promise<any> {
        const data = {
            ids: ids,
        };
        return this.postRequest(`${this.baseUrl}/multiple_reserved`, data);
    }
}
