import { Component, Injector, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { PrimeNGConfig } from 'primeng/api';
import { AppInjectorService } from './services/app-injector.service';

import { FirebaseService } from './services/firebase.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    message: any;
    constructor(
        private config: PrimeNGConfig,
        private translateService: TranslateService,
        private injector: Injector,
        private firebaseService: FirebaseService
    ) {
        AppInjectorService.injector = injector;
    }

    public ngOnInit(): void {
        this.translateService.setDefaultLang('fr');
        this.translateService.use('fr');
        this.translateService
            .get('primeng')
            .subscribe((res) => this.config.setTranslation(res));
        this.firebaseService.requestPermission();
        this.firebaseService.receiveMessage();
        this.message = this.firebaseService.currentMessage;
    }
    title = 'test';
}
