import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { DialogService } from 'primeng/dynamicdialog';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import Utils from '../components/shared/utils';
import { PaginatedParams } from '../models/paginated-params';
import { QueryParams } from '../models/query-params';

export class ApiService {
    private httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
    });

    constructor(
        private httpClient: HttpClient,
        private dialService: DialogService
    ) {}

    /**
     * @params url => Url to call with POST request
     * @param data => Data to send in the POST body
     * @returns {Promise<T>}
     */
    public async postRequest<T>(
        url: string,
        data: { [key: string]: any },
        queryParams?: QueryParams
    ): Promise<T> {
        let httpParams = this.setHttpParams(queryParams);
        return this.httpClient
            .post<T>(`${environment.apiHost}/${url}/`, data, {
                headers: this.httpHeaders,
                params: httpParams,
            })
            .pipe(
                tap({
                    error: (error) => {
                        if (error?.error?.error !== 'Incorrect old password.') {
                            Utils.openModalError(
                                this.dialService,
                                'Chargement',
                                error.message
                            );
                        }
                    },
                })
            )
            .toPromise();
    }

    /**
     * @params url => Url to call with GET request to get an Array of objects
     * @returns {Promise<any[]>}
     */
    getRequest(url: string, queryParams?: QueryParams): Promise<any[]> {
        let httpParams = this.setHttpParams(queryParams);

        return this.httpClient
            .get<any[]>(`${environment.apiHost}/${url}/`, {
                headers: this.httpHeaders,
                params: httpParams,
            })
            .pipe(
                tap({
                    error: (error) => {
                        if (
                            error.status &&
                            (error.status === 404 || error.status === 500)
                        ) {
                            Utils.openModalError(
                                this.dialService,
                                'Chargement',
                                error.message
                            );
                        }
                    },
                })
            )
            .toPromise();
    }

    /**
     * @params url => Url to call with GET request to get a single Object
     * @returns {Promise<T>}
     */
    getDetailRequest<T>(url: string, queryParams?: QueryParams): Promise<T> {
        let httpParams = this.setHttpParams(queryParams);

        return this.httpClient
            .get<T>(`${environment.apiHost}/${url}/`, {
                headers: this.httpHeaders,
                params: httpParams,
            })
            .pipe(
                tap({
                    error: (error) => {
                        Utils.openModalError(
                            this.dialService,
                            'Chargement',
                            error.message
                        );
                    },
                })
            )
            .toPromise();
    }

    /**
     * @params url => Url to call with PUT request
     * @param data => Data to send in the PUT body
     * @returns {Promise<T>}
     */
    putRequest<T>(url: string, data: T, queryParams?: QueryParams): Promise<T> {
        let httpParams = this.setHttpParams(queryParams);
        return this.httpClient
            .patch<T>(`${environment.apiHost}/${url}/`, data, {
                headers: this.httpHeaders,
                params: httpParams,
            })
            .pipe(
                tap({
                    error: (error) => {
                        Utils.openModalError(
                            this.dialService,
                            'Chargement',
                            error.message
                        );
                    },
                })
            )
            .toPromise();
    }

    /**
     * @params url => Url to call with PATCH request
     * @param data => Data to send in the PATCH body
     * @returns {Promise<T>}
     */
    patchRequest<T>(
        url: string,
        data: { [key: string]: any },
        queryParams?: QueryParams
    ): Promise<T> {
        let httpParams = this.setHttpParams(queryParams);
        return this.httpClient
            .patch<T>(`${environment.apiHost}/${url}/`, data, {
                headers: this.httpHeaders,
                params: httpParams,
            })
            .pipe(
                tap({
                    error: (error) => {
                        Utils.openModalError(
                            this.dialService,
                            'Chargement',
                            error.message
                        );
                    },
                })
            )
            .toPromise();
    }

    /**
     * @params url => Url to call with DELETE request
     * @returns {Promise<any>}
     */
    deleteRequest<T>(url: string, queryParams?: QueryParams): Promise<T> {
        let httpParams = this.setHttpParams(queryParams);
        return this.httpClient
            .delete<T>(`${environment.apiHost}/${url}/`, {
                headers: this.httpHeaders,
                params: httpParams,
            })
            .pipe(
                tap({
                    error: (error) => {
                        Utils.openModalError(
                            this.dialService,
                            'Chargement',
                            error.message
                        );
                    },
                })
            )
            .toPromise();
    }

    /**
     * @params url => Url to call with GET request, paginated
     * @param data => Data to send in the PATCH body
     * @returns {Promise<T>}
     */
    paginatedRequest<T>(
        url: string,
        queryParams?: QueryParams,
        paginatedParams?: PaginatedParams,
        additionnalParams?: { [key: string]: any }
    ): Promise<T> {
        let params = this.setHttpParams(
            queryParams,
            paginatedParams,
            additionnalParams
        );
        return this.httpClient
            .get<T>(`${environment.apiHost}/${url}/`, { params })
            .pipe(
                tap({
                    error: (error) => {
                        Utils.openModalError(
                            this.dialService,
                            'Chargement',
                            error.message
                        );
                    },
                })
            )
            .toPromise();
    }

    protected setHttpParams(
        queryParams?: QueryParams,
        paginatedParams?: PaginatedParams,
        additionnalParams?: { [key: string]: any }
    ): HttpParams {
        let httpParams = new HttpParams();
        let params: HttpParams;
        if (queryParams) {
            params = Utils.paramsUrlFlexFields(
                httpParams,
                queryParams.fields,
                queryParams.expand,
                queryParams.omit
            );

            if (queryParams.filters) {
                Object.keys(queryParams.filters).forEach((key) => {
                    if (Utils.isNotNil(queryParams.filters[key])) {
                        params = params.append(key, queryParams.filters[key]);
                    }
                });
            }
        }

        if (paginatedParams) {
            Object.keys(paginatedParams).forEach((key) => {
                if (paginatedParams[key]) {
                    params = params.append(key, paginatedParams[key]);
                }
            });
        }

        if (additionnalParams) {
            Object.keys(additionnalParams).forEach((key) => {
                if (additionnalParams[key]) {
                    params = params.append(key, additionnalParams[key]);
                }
            });
        }

        return params;
    }
}
