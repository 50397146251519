/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
    Component,
    EventEmitter,
    Input,
    Output,
    TemplateRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApolloButtonComponent } from '../apollo-button/apollo-button.component';
import { ButtonTypeEnum } from 'src/app/enums/button-type';
import { TooltipModule } from 'primeng/tooltip';

@Component({
    selector: 'app-modal-buttons',
    standalone: true,
    templateUrl: './modal-buttons.component.html',
    styleUrls: ['./modal-buttons.component.scss'],
    imports: [CommonModule, TooltipModule, ApolloButtonComponent],
})
export class ModalButtonsComponent {
    buttonType = ButtonTypeEnum;

    @Input() confirmLabel: string = 'Enregistrer';
    @Input() cancelLabel: string = 'Annuler';
    @Input() beforeTemplate: TemplateRef<any>;
    @Input() betweenTemplate: TemplateRef<any>;
    @Input() afterTemplate: TemplateRef<any>;
    @Input() useSubmit: boolean = true;
    @Input() displayConfirm: boolean = true;
    @Input() disableConfirm: boolean = false;
    @Input() displayCancel: boolean = true;
    @Input() disableCancel: boolean = false;
    @Input() tooltipCancel: string;
    @Input() icon: string = 'check';
    @Input() typeConfirm: ButtonTypeEnum = this.buttonType.secondary;

    @Output() onCancelClick: EventEmitter<void> = new EventEmitter<void>();
    @Output() onConfirmClick: EventEmitter<void> = new EventEmitter<void>();

    constructor() {}

    cancelClick(): void {
        this.onCancelClick.emit();
    }

    confirmClick(): void {
        this.onConfirmClick.emit();
    }
}
