import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Account } from '../models/account';

@Injectable({
    providedIn: 'root',
})
export class IdAccountService {
    public accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;
    public IdAccountService: number;

    constructor() {
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject?.asObservable();
    }

    public getIdAccountService(): number {
        let id: number = null;
        if (!this.accountSubject?.value) {
            const account = this.currentAccount;
            id = account?.id;
        } else {
            id = this.accountSubject?.value.id;
        }
        return id;
    }

    public clearAccount(): void {
        localStorage.removeItem('currentAccount');
        this.accountSubject = new BehaviorSubject<Account>(null);
    }

    public get currentAccount(): Account {
        if (!this.accountSubject?.value) {
            let account = JSON.parse(
                localStorage.getItem('currentAccount')
            ) as Account;
            this.accountSubject?.next(account);
        }
        return this.accountSubject?.value;
    }
}
