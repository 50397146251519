<div style="gap: 2rem" class="p-d-flex p-ai-center p-jc-center modal-button">
    <ng-container [ngTemplateOutlet]="beforeTemplate"></ng-container>

    <app-apollo-button *ngIf="displayCancel" icon="times" customClass="white-bg" iconPos="left" [label]="cancelLabel"
        [type]="buttonType.outlined" (onClick)="cancelClick()" [disabled]="disableCancel"
        [pTooltip]="tooltipCancel"></app-apollo-button>

    <ng-container [ngTemplateOutlet]="betweenTemplate"></ng-container>

    <app-apollo-button *ngIf="useSubmit && displayConfirm" [disabled]="disableConfirm" [icon]="icon"
        [label]="confirmLabel" [type]="buttonType.submit"></app-apollo-button>

    <app-apollo-button *ngIf="!useSubmit && displayConfirm" [icon]="icon" [disabled]="disableConfirm"
        [label]="confirmLabel" [type]="typeConfirm" (onClick)="confirmClick()"></app-apollo-button>

    <ng-container [ngTemplateOutlet]="afterTemplate"></ng-container>
</div>