import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Injectable({
    providedIn: 'root',
})
export class ProviderContactPermissionService extends ApiService {
    private baseUrl: string;

    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        private authenticationService: AuthenticationService
    ) {
        super(http, dialogService);
        this.baseUrl = 'api/thot';
    }

    public getProviderUserPermissions(id: number): Promise<string[]> {
        return this.getRequest(
            `${this.baseUrl}/provider_contacts/${id}/permissions`
        );
    }

    public async hasPermission(component: any): Promise<boolean> {
        const PermissionUser = await this.getProviderUserPermissions(
            this.authenticationService.currentUser.contact.id
        );
        return PermissionUser.includes(component.routeConfig.data.permissions);
    }
}
