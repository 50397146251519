import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { PaginatedParams } from '../models/paginated-params';
import { QueryParams } from '../models/query-params';
import { ApiService } from './api.service';
import { IdAccountService } from './id-account.service';

@Injectable({
    providedIn: 'root',
})
export class AgentActivityReportService extends ApiService {
    baseUrl: string;
    constructor(
        protected http: HttpClient,
        protected dialogService: DialogService,
        private IdAccountService: IdAccountService
    ) {
        super(http, dialogService);
        this.baseUrl = `api/thot/accounts/${this.IdAccountService.getIdAccountService()}/iris/agentActivityReport`;
    }

    public getAgentActivityReport(
        queryParams: QueryParams,
        paginatedParams: PaginatedParams
    ): Promise<any> {
        return this.paginatedRequest(
            `${this.baseUrl}`,
            queryParams,
            paginatedParams
        );
    }

    public getAgentActivityReportDetail(
        id: number,
        queryParams: QueryParams
    ): Promise<{ [key: string]: any }> {
        return this.getDetailRequest(`${this.baseUrl}/${id}`, queryParams);
    }
}
