import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private auth: AuthenticationService) {}

    public intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((err) => {
                if ([0, 401, 403].includes(err.status)) {
                    // auto logout if 0 or 401 or 403 response returned from api
                    if (
                        err.error.detail === 'Utilisateur inconnu' ||
                        err.error.detail?.startsWith('Mot de passe incorrect.')
                    ) {
                        return throwError(err);
                    } else {
                        this.auth.logout();
                    }
                }
                // const error = (err && err.error && err.error.message) || err.statusText;
                return throwError(err);
            })
        );
    }
}
