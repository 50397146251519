import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { Table } from 'primeng/table';
import { ButtonTypeEnum } from 'src/app/enums/button-type';

@Component({
    standalone: true,
    selector: 'app-search-component',
    imports: [FormsModule, ReactiveFormsModule, InputTextModule],
    templateUrl: './search-component.component.html',
    styleUrls: ['./search-component.component.scss'],
})
export class SearchComponentComponent {
    @Input() table: Table;
    @Input() searchText: string;

    @Output() searchTextChange = new EventEmitter();

    public buttonEnum = ButtonTypeEnum;
    constructor() {}

    filterGlobal(event: Event, str: string) {
        this.table.filterGlobal((event.target as HTMLInputElement).value, str);
        this.searchTextChange.emit((event.target as HTMLInputElement).value);
    }
}
