/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { ApolloButtonComponent } from '../apollo-button/apollo-button.component';

@Component({
    standalone: true,
    selector: 'app-date-selector',
    templateUrl: './date-selector.component.html',
    styleUrls: ['./date-selector.component.scss'],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CalendarModule,
        TooltipModule,
        ApolloButtonComponent,
    ],
})
export class DateSelectorComponent {
    @Input() styleClass: string;
    @Input() startDate: Date;
    @Input() startDatePlaceholder: string = 'Date de début';
    @Input() endDatePlaceholder: string = 'Date de fin';
    @Input() dateFormat: string = 'dd/mm/yy';
    @Input() monthSelector: boolean = false;
    @Input() startDateDisabled: boolean = false;
    @Input() endDateDisabled: boolean = false;
    @Input() endDate?: Date;
    @Input() useEndDate: boolean = true;
    @Input() startMinDate?: Date;
    @Input() startMaxDate?: Date;
    @Input() endMinDate?: Date;
    @Input() endMaxDate?: Date;
    @Input() startDateTooltip?: string;
    @Input() endDateTooltip?: string;
    @Input() startDisabledDates?: Date[];
    @Input() showTodayButton: boolean = false;

    @Output() startDateChange: EventEmitter<Date> = new EventEmitter();
    @Output() endDateChange: EventEmitter<Date> = new EventEmitter();

    @Output() onStartDateChanged: EventEmitter<Date> = new EventEmitter();
    @Output() onEndDateChanged: EventEmitter<Date> = new EventEmitter();
    @Output() onStartDateClosed: EventEmitter<Date> = new EventEmitter();
    @Output() onEndDateClosed: EventEmitter<Date> = new EventEmitter();
    @Output() onMonthChanged: EventEmitter<any> = new EventEmitter();
    @Output() onShow: EventEmitter<any> = new EventEmitter();

    constructor() {}

    startDateClose() {
        this.onStartDateClosed.emit(this.startDate);
    }

    endDateClose() {
        this.onEndDateClosed.emit(this.endDate);
    }

    startDateSelect(date: Date) {
        this.endMinDate = date;
        this.startDate = date;
        this.startDateChange.emit(this.startDate);
        this.onStartDateChanged.emit();
    }

    endDateSelect(date: Date) {
        this.endDate = date;
        this.endDateChange.emit(this.endDate);
        this.onEndDateChanged.emit();
    }

    monthChanged(e: { month: number; year: number }) {
        this.onMonthChanged.emit(e);
    }

    show() {
        this.onShow.emit();
    }
}
