/* eslint-disable @angular-eslint/no-output-on-prefix */
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonTypeEnum } from 'src/app/enums/button-type';
import { BadgeModule } from 'primeng/badge';

@Component({
    selector: 'app-apollo-button',
    standalone: true,
    imports: [CommonModule, ButtonModule, TooltipModule, BadgeModule],
    templateUrl: './apollo-button.component.html',
    styleUrls: ['./apollo-button.component.scss'],
})
export class ApolloButtonComponent implements OnChanges {
    @Input() useFaIcon: boolean = false;
    @Input() faIcon: string;
    @Input() disabled: boolean = false;
    @Input() useImg: boolean = false;
    @Input() icon: string;
    @Input() altImg: string;
    @Input() srcImg: string;
    @Input() label: string;
    @Input() type: ButtonTypeEnum = ButtonTypeEnum.primary;
    @Input() useSpacing: boolean = true;
    @Input() useLargeButton: boolean = false;
    @Input() badge: string = '0';
    @Input() useBadge: boolean = false;
    @Input() tooltip: string;
    @Input() tooltipPosition: 'top' | 'bottom' | 'left' | 'right';
    @Input() useOutline: boolean = false;
    @Input() useText: boolean = false;
    @Input() customClass: string;
    @Input() iconPos: 'right' | 'left' = 'left';
    @Output()
    onClick: EventEmitter<any> = new EventEmitter();

    public buttonIcon: string;
    public buttonStyle: string;
    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (!this.useFaIcon && this.icon) {
            this.buttonIcon = `pi pi-${this.icon}`;
        }
        this.buttonStyle = `${
            !this.label ? 'p-button-rounded' : ''
        } ${this.setButtonType()} ${
            this.useFaIcon && this.label == '' ? 'fa-button' : ''
        } ${this.useSpacing ? 'p-ml-2 p-mr-2' : ''} ${
            this.useLargeButton ? 'large-button' : ''
        } ${!this.useLargeButton && this.useBadge ? 'badge-button' : ''} ${
            this.useOutline ? 'p-button-outlined' : ''
        } ${this.useText ? 'p-button-text' : ''}`;
    }

    clickFunction() {
        if (!this.disabled) {
            this.onClick.emit();
        }
    }

    setButtonType(): string {
        switch (this.type) {
            default:
                return '';
            case 'outlined':
                return 'p-button-outlined';
            case 'secondary':
                return 'p-button-success';
            case 'danger':
                return 'p-button-danger';
            case 'text':
                return 'p-button-text';
            case 'danger-text':
                return 'p-button-danger p-button-text';
            case 'submit':
                return 'p-button-success';
            case 'warning':
                return 'p-button-warning';
            case 'submit-primary':
                return '';
        }
    }
}
